@import url(//fonts.googleapis.com/earlyaccess/notosanstc.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Noto Sans TC',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.searchBarParent {
    margin: 0;
    padding: 0;
    color: #000000;
    list-style: none;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9;
    width: 100%;
    height: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.searchBarItemTitle {
    padding: 8px 8px;
    font-weight: normal;
    border-right: 1px solid #d9d9d9;
    font-size: 15px;
    text-align: center;
    background: #fafafa;
    border-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    transition: all 0.3s;
}
.app-switcher-popover {
  position: fixed;
}

.app-switcher-popover .ant-popover-arrow {
  display: none;
}

.layout-header-app-switcher {
  position: fixed;
  top: 0px;
  right: 24px;
}

.app-switcher-popover .ant-popover-inner-content {
  padding: 0px;
}

.app-switcher-popover.ant-popover {
  line-height: 0;
}

.layout-header-app-switcher-btn:hover {
  background: rgba(0, 0, 0, 0.1);
}

.mask {
-webkit-mask-image: linear-gradient(to top, transparent -1%, white 10%);
mask-image: linear-gradient(to top, transparent -1%, white 10%);
}

@media (max-width: 576px) and (orientation: portrait) {
  .app-switcher {
    width: 100vw;
    height: calc(100vh - 64px);
  }
}

@media (min-width: 577px) and (orientation: portrait) {
  .app-switcher {
    width: 320px;
    height: 370px;
  }
}

@media (max-height: 576px) and (orientation: landscape) {
  .app-switcher {
    width: 320px;
    height: calc(100vh - 64px);
  }
}

@media (min-height: 577px) and (orientation: landscape) {
  .app-switcher {
    width: 320px;
    height: 370px;
  }
}
/*  -------------  layout header ------------- */
.layout-header {
    padding: 0px 0px;
    background: #FFFFFF;
    border-bottom: solid 1px #F0F2F4;
    position: fixed;
    z-index: 999;
    width: 100%;
}

.layout-header-content{
    background: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    justify-content: flex-start;
    border-bottom:  1px solid #F0F2F4;
    height: 100%;
}

.collapse-trigger {
    font-size: 20px;
    padding: 0 24px;
}

.collapse-trigger:hover {
    color: #1890ff;
}

.layout-header-breadcrumb-home:hover {
    color: #1890ff;
}

.layout-header-app-release {
    position: fixed;
    top: 0px;
    right: 72px;
}

.app-release-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 32px;
    height: 64px;
    line-height: 64px;
}

.app-release-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.app-release-btn:hover {
    background: rgba(0, 0, 0, 0.1);
}

/*  -------------  layout sider ------------- */
.layout-sider {
    height: 100vh;
    overflow: hidden hidden;
    position: fixed;
    left: 0;
}

.sider-header {
    color: white;
    cursor: pointer;
}

.sider-menu {
    height: calc(100vh - var(--sider-header) - var(--sider-footer) - 16px);
}


/*  -------------  layout content ------------- */
.layout-content {
    min-height: calc(100vh - 64px - 48px);
    padding: 24px 24px;
    margin: 88px 16px 24px;
    overflow: auto;
    background: white;
}

/*  -------------  layout footer ------------- */
.layout-footer {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    background: #fff;
    text-align: center;
}

/*  -------------  general ------------- */
.layout-sider, .layout-footer, .ant-menu-item, .layout-header-breadcrumb {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

/* .logo {
    position: relative;
    width: 100%;
    height: 50px;
    text-align: center;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding-top: 10px;
} */


